var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"add"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"试卷名称"}},[_c('el-input',{model:{value:(_vm.form.source),callback:function ($$v) {_vm.$set(_vm.form, "source", $$v)},expression:"form.source"}})],1),_c('el-form-item',{attrs:{"label":"简介"}},[_c('el-input',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('el-form-item',{attrs:{"label":"考试类型"}},[_c('el-radio',{attrs:{"label":"0","border":""},on:{"change":function($event){return _vm.change(_vm.form.isroot)}},model:{value:(_vm.form.isroot),callback:function ($$v) {_vm.$set(_vm.form, "isroot", $$v)},expression:"form.isroot"}},[_vm._v("单次考试")]),_c('el-radio',{attrs:{"label":"1","border":""},on:{"change":function($event){return _vm.change(_vm.form.isroot)}},model:{value:(_vm.form.isroot),callback:function ($$v) {_vm.$set(_vm.form, "isroot", $$v)},expression:"form.isroot"}},[_vm._v("过程考试")])],1),_c('div',{attrs:{"id":"context"}},[_c('el-form-item',{attrs:{"label":"考试日期"}},[_c('el-col',{attrs:{"span":11}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"placeholder":"选择日期"},model:{value:(_vm.form.examDate),callback:function ($$v) {_vm.$set(_vm.form, "examDate", $$v)},expression:"form.examDate"}})],1)],1),_c('el-form-item',{attrs:{"label":"开始时间"}},[_c('el-time-select',{attrs:{"placeholder":"起始时间","picker-options":{
                start: '08:30',
                step: '00:15',
                end: '22:30'
               }},on:{"change":_vm.sumTimes},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1),_c('el-form-item',{attrs:{"label":"结束时间"}},[_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
                start: '08:30',
                step: '00:15',
                end: '23:30',
                minTime: _vm.startTime
         }},on:{"change":_vm.sumTimes},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1),_c('el-form-item',{attrs:{"label":"持续时间"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form.totalTime),callback:function ($$v) {_vm.$set(_vm.form, "totalTime", $$v)},expression:"form.totalTime"}})],1),_c('el-form-item',{attrs:{"label":"考生提示"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.tips),callback:function ($$v) {_vm.$set(_vm.form, "tips", $$v)},expression:"form.tips"}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("立即创建")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("取消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }